import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";
import useDeviceSize from "../hooks/use-device-size";
import "./ess.scss";
import "../sass/_orphan.scss";
import bannerImg from "../images/ess/chris-social-header-desktop.webp";
import bannerImgMobile from "../images/ess/chris-social-header-mobile.webp";
import pdfThumb from "../images/ess/ess-download.webp";
import heroName from "../images/ess/hero-ess-name.webp";

const EssPage = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout
            pageClass="ess-page orphan-page"
            jobCode="US-WAK-2400432/Jul 2024"
            copyRightYear="2024"
            noStickyHCPLocatorCTA={true}
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                className="banner-mobile"
                                alt="Talking About Your Treatment"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                className="banner-desktop"
                                alt="Talking About Your Treatment"
                            />
                        )}
                        <div className="centered-content">
                            <div className="hero-name">
                                <img
                                    src={heroName}
                                    alt="Chris - Living with narcolepsy Taking WAKIX since 2021"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="centered-content">
                <div className="page-heading ph-btm">
                    <h1 className="font-40">
                        Track Your Level of Daytime Sleepiness Over Time
                    </h1>
                </div>
            </section>

            <section className="gray-bg cta-block">
                <div className="centered-content">
                    <Row className="ess-row">
                        <Col xs={12} md={9} className="ess-col">
                            <div className="content-copy">
                                <h2>The Epworth Sleepiness Scale</h2>
                                <p className="top-spacer btm-spacer">
                                    The Epworth Sleepiness Scale (ESS) is a 0-
                                    to 24-point scale for adults based on an
                                    8-item questionnaire that asks you to rate
                                    how likely you are to doze or fall asleep in
                                    everyday situations.
                                </p>
                                <p className="top-spacer btm-spacer">
                                    Complete this scale when you first begin
                                    treatment with WAKIX and then periodically
                                    during your first several months on
                                    treatment to track any changes in your score
                                    over time. You can also ask a family member
                                    or close friend to help you complete the
                                    scale. They may recognize changes in your
                                    daytime sleepiness that you haven't noticed.
                                </p>
                                <p className="top-spacer btm-spacer">
                                    Talk to your healthcare provider about any
                                    changes in your daytime sleepiness to help
                                    them understand how you are doing on WAKIX.
                                </p>
                                <div className="btn-wrap">
                                    <a
                                        href="/pdf/Track-excessive-daytime-sleepiness-with-ESS.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                    >
                                        Download the scale
                                        <svg
                                            id="download_arrow"
                                            data-name="download arrow"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            width="21"
                                            height="22"
                                            viewBox="0 0 21 22"
                                        >
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <path
                                                        id="Clip_4"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                </clipPath>
                                            </defs>
                                            <path
                                                id="Fill_1"
                                                data-name="Fill 1"
                                                d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                transform="translate(0 17)"
                                                fill="#fff"
                                            />
                                            <g
                                                id="Group_5"
                                                data-name="Group 5"
                                                transform="translate(9)"
                                            >
                                                <path
                                                    id="Clip_4-2"
                                                    data-name="Clip 4"
                                                    d="M0,0H2V16H0Z"
                                                    fill="none"
                                                />
                                                <g
                                                    id="Group_5-2"
                                                    data-name="Group 5"
                                                    clipPath="url(#clip-path)"
                                                >
                                                    <path
                                                        id="Fill_3"
                                                        data-name="Fill 3"
                                                        d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </g>
                                            <path
                                                id="Fill_6"
                                                data-name="Fill 6"
                                                d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                transform="translate(3 9)"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={3} className="ess-col ess-col-2">
                            <img
                                src={pdfThumb}
                                alt="Track Your Level of Daytime Sleepiness Over Time Thumbnail"
                                className="box-shadow"
                            />
                        </Col>
                    </Row>
                </div>
            </section>
        </HcpLayout>
    );
};

export default EssPage;

export const Head = () => (
    <Seo
        title="Track Your Level of Daytime Sleepiness Over Time | WAKIX® (pitolisant) tablets"
        description=" "
        keywords=""
    />
);
